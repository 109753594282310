import _ from 'lodash';
import { GetServerSidePropsResult } from 'next';
import Image from 'next/image';
import { useEffect } from 'react';
import { styled } from 'stitches.config';
import WSCard from '../base-widgets/ws-card';
import { WSFlex } from '../base-widgets/ws-flex';
import { WSSeparator } from '../base-widgets/ws-separator';
import WSText from '../base-widgets/ws-text';
import DevToolbox from './dev-toolbox';

export type AuthPagePrefetchedData = {
  testimonial: Testimonial;
  trustedBys: TrustedBy[];
};

export async function getServerSideProps(): Promise<GetServerSidePropsResult<AuthPagePrefetchedData>> {
  // Pick the randomly-selected elements on the server so they are ready for first render on the client.
  return {
    props: {
      testimonial: _.sample(TESTIMONIALS_OPTIONS) ?? TESTIMONIALS_OPTIONS[0],
      trustedBys: _.sampleSize(TRUSTED_BY_OPTIONS, 3) ?? [],
    },
  };
}

type TrustedBy = { imageUrl: string; alt: string };

type Testimonial = {
  name: string;
  since: string;
  body: string;
  imageUrl: string;
};

const TRUSTED_BY_OPTIONS: TrustedBy[] = [
  { imageUrl: '/assets/trusted-by/ncode.png', alt: 'Ncode logo' },
  { imageUrl: '/assets/trusted-by/userspots.png', alt: 'Userspots logo' },
  { imageUrl: '/assets/trusted-by/subfolder.png', alt: 'Subfolder logo' },
  { imageUrl: '/assets/trusted-by/homestead.png', alt: 'Homestead logo' },
  { imageUrl: '/assets/trusted-by/copy_ai.png', alt: 'copy.ai logo' },
  { imageUrl: '/assets/trusted-by/laudable.png', alt: 'laudable logo' },
  { imageUrl: '/assets/trusted-by/socialmotion.png', alt: 'Socialmotionpacks logo' },
];

const TESTIMONIALS_OPTIONS: Testimonial[] = [
  {
    name: 'Aaron R',
    since: 'May 2022',
    body: "Whalesync is a godsend for the project I'm working on!",
    imageUrl: '/assets/testimonials/aaron_r.jpg',
  },
  {
    name: 'Aiva G',
    since: 'Jun 2022',
    body: 'Webflow + Whalesync + Airtable = everything you need and more.',
    imageUrl: '/assets/testimonials/aiva_g.jpg',
  },
  {
    name: 'art_lover',
    since: 'Jun 2022',
    body: 'Cannot recommend it enough - as valuable as Airtable and weblow alone imo.',
    imageUrl: '/assets/testimonials/art_lover.jpg',
  },
  {
    name: 'Cait T',
    since: 'May 2022',
    body: 'Whalesync is an absolute game changer - I wish I had this a year ago when we were building our current website!',
    imageUrl: '/assets/testimonials/cait_t.jpg',
  },
  {
    name: 'Christine C',
    since: 'May 2022',
    body: 'I absolutely love Whalesync and the insights it provides into the errors.',
    imageUrl: '/assets/testimonials/christine_c.jpg',
  },
  {
    name: 'Cody A',
    since: 'May 2022',
    body: 'It is a simple, fast and absolutely fantastic product which will make your life 10x easier at least.',
    imageUrl: '/assets/testimonials/cody_a.jpg',
  },
  {
    name: 'Frazer M',
    since: 'May 2022',
    body: '🤯🤯 Pure magic.',
    imageUrl: '/assets/testimonials/frazer_m.jpg',
  },
  {
    name: 'Hausia S',
    since: 'Apr 2022',
    body: "This service is lightyears ahead of other apps I've tried.",
    imageUrl: '/assets/testimonials/hausia_s.jpg',
  },
  {
    name: 'Ian R',
    since: 'May 2022',
    body: 'Has really made updating CMS data a breeze for my no-code programmatic SEO project.',
    imageUrl: '/assets/testimonials/ian_r.jpg',
  },
  {
    name: 'Iar R',
    since: 'Mar 2022',
    body: 'Works simply perfect. Today my #nocode dev process become faster and more powerful',
    imageUrl: '/assets/testimonials/iar_r.jpg',
  },
  {
    name: 'Jake M',
    since: 'Apr 2022',
    body: 'Love it! Super easy to set up.',
    imageUrl: '/assets/testimonials/jake_m.jpg',
  },
  {
    name: 'Jay A',
    since: 'Jul 2022',
    body: 'Finally figured out how to do Programmatic SEO on Webflow. Whalesync makes it easy.',
    imageUrl: '/assets/testimonials/jay_a.jpg',
  },
  {
    name: 'Joachim B',
    since: 'Jul 2022',
    body: "The best out there. I've tried parabola, nobull, make,... Whalesync is just flawless.",
    imageUrl: '/assets/testimonials/joachim_b.jpg',
  },
  {
    name: 'Joe H',
    since: 'Apr 2022',
    body: 'My jaw is in the f****** floor - whalesync rocks.',
    imageUrl: '/assets/testimonials/joe_h.jpg',
  },
  {
    name: 'Josse M',
    since: 'Jul 2022',
    body: 'You guys literally saved me so much time.',
    imageUrl: '/assets/testimonials/josse_m.jpg',
  },
  {
    name: 'Paul R',
    since: 'Apr 2022',
    body: "It's awesome! It's just the perfect tool 💪",
    imageUrl: '/assets/testimonials/paul_r.jpg',
  },
  {
    name: 'Steve S',
    since: 'Apr 2022',
    body: 'It works like a Dream! 👌',
    imageUrl: '/assets/testimonials/steve_s.jpg',
  },
  {
    name: 'Walker M',
    since: 'Jun 2022',
    body: "If you're looking to use Airtable as a headless CMS with Webflow, Whalesync is the tool for you!",
    imageUrl: '/assets/testimonials/walker_m.jpg',
  },
];

const TestimonalImage = styled('div', { borderRadius: 99999, overflow: 'clip', width: '65px', height: '65px' });

function BulletCircle(): JSX.Element {
  return <Image src="/assets/blue-circle.png" alt="Bullet" width={19} height={19} />;
}

function AuthPage(props: {
  prefetchedProps: AuthPagePrefetchedData;
  title: string;
  children: JSX.Element | JSX.Element[];
}): JSX.Element {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  return (
    <WSFlex direction="row" fullWidth fullHeight>
      <WSFlex
        direction="column"
        css={{
          flexBasis: '500px',
          flexGrow: 0,
          flexShrink: 1,
          backgroundColor: '$loginScreenDark',
          padding: '100px 50px 50px 50px',
          '@oneColumnSigninPage': {
            display: 'none',
          },
        }}
      >
        <span style={{ alignSelf: 'start' }}>
          <Image src="/assets/whalesync-logo-white.png" alt="whalesync logo" width={286} height={38} />
        </span>
        {props.prefetchedProps.testimonial && (
          <WSCard css={{ marginTop: 'auto' }}>
            <WSFlex direction="column" css={{ gap: '12px' }}>
              <WSFlex align="center" css={{ gap: '12px' }}>
                <TestimonalImage>
                  <Image
                    src={props.prefetchedProps.testimonial.imageUrl}
                    loading="lazy"
                    alt=""
                    width={65}
                    height={65}
                  />
                </TestimonalImage>
                <div>
                  <WSText variant="h4" css={{ color: '$gray9' }}>
                    {props.prefetchedProps.testimonial.name}
                  </WSText>
                  <WSText variant="body">Member since {props.prefetchedProps.testimonial.since}</WSText>
                </div>
              </WSFlex>
              <WSText variant="bodyLarge">{props.prefetchedProps.testimonial.body}</WSText>
            </WSFlex>
          </WSCard>
        )}

        <WSText variant="body" css={{ color: 'white' }}>
          <WSFlex justify="between" align="center" css={{ marginTop: '20px', gap: '8px' }}>
            <BulletCircle />
            <span>2-way sync</span>
            <BulletCircle />
            <span>Real-time updates</span>
            <BulletCircle />
            <span>Simple design</span>
          </WSFlex>
        </WSText>
        <WSText variant="body" css={{ color: '$gray4', marginTop: 'auto', lineHeight: 3 }}>
          Trusted by teams who work smarter
        </WSText>
        <WSSeparator css={{ backgroundColor: '$gray4' }} />
        <WSFlex justify="center" align="center" css={{ gap: '12px' }}>
          {props.prefetchedProps.trustedBys.map((tbl, index) => (
            <Image key={index} src={tbl.imageUrl} alt={tbl.alt} width={100} height={100} objectFit="contain" />
          ))}
        </WSFlex>
      </WSFlex>

      {props.children}

      <DevToolbox />
    </WSFlex>
  );
}

export default AuthPage;
