import * as Collapsible from '@radix-ui/react-collapsible';
import Link from 'next/link';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { styled } from 'stitches.config';
import { chooseEndpoint } from '../../lib/client/v1/methods';
import {
  ClientFlag,
  FLAGS,
  Flavor,
  getStoredUserToImpersonateId,
  isExperimentEnabled,
  setStoredEndpointOverride,
  setStoredUserToImpersonateId,
} from '../../utils/flags';
import { stringToEnum } from '../../utils/helpers';
import { RouteUrls } from '../../utils/route-urls';
import { UserInfo } from '../../utils/types';
import { WSFlex } from '../base-widgets/ws-flex';
import { WSSeparator } from '../base-widgets/ws-separator';
import WSText from '../base-widgets/ws-text';
import WSTextField from '../base-widgets/ws-textfield';
import { useClerkAuth } from '../contexts/clerk-auth';

export function shouldShowDevTools(user: UserInfo | null): boolean {
  return isExperimentEnabled('DUSKY_DEV_TOOLBOX', user);
}

/** This adds the client flag FLAGS.INVISIBLE_DEV_TOOLBOX to shouldShowDevTools */
export function shouldVisibleDevTool(user: UserInfo | null): boolean {
  return shouldShowDevTools(user) && !FLAGS.INVISIBLE_DEV_TOOLBOX.get();
}

/** Contains tools developers can use to debug the app. */
function DevToolbox(): JSX.Element {
  const { authState } = useClerkAuth();
  const user = authState.user;
  const [isOpen, setIsOpen] = useState(false);
  const [needsRefresh, setNeedsRefresh] = useState(false);

  useEffect(() => {
    if (!isOpen && needsRefresh && typeof window !== 'undefined') {
      window.location.reload();
    }
  }, [isOpen, needsRefresh]);

  const StyledCollapsibleRoot = styled(Collapsible.Root, {
    position: 'absolute',
    top: 0,
    left: 'calc(50% - 125px)',
  });

  const StyledCollapsibleTrigger = styled(Collapsible.Trigger, {
    backgroundColor: '$primary',
    color: 'white',
    border: 'none',
    padding: '12px',
    width: '250px',
    height: '40px',
  });

  const StyledInvisibleCollapsibleTrigger = styled(Collapsible.Trigger, {
    backgroundColor: 'transparent',
    border: 'none',
    width: '250px',
    height: '40px',
  });

  const StyledCollapsibleContent = styled(Collapsible.Content, {
    backgroundColor: '$accent6',
    color: 'white',
    padding: '30px',
    width: '250px',
    borderTop: '1px solid $accent1',
  });

  if (!shouldShowDevTools(user)) {
    return <></>;
  }

  return (
    <StyledCollapsibleRoot open={isOpen} onOpenChange={setIsOpen}>
      {FLAGS.INVISIBLE_DEV_TOOLBOX.get() ? (
        <StyledInvisibleCollapsibleTrigger />
      ) : (
        <StyledCollapsibleTrigger>Dev Tools</StyledCollapsibleTrigger>
      )}
      <StyledCollapsibleContent>
        <WSFlex
          direction={'column'}
          css={{
            gap: '24px',
          }}
        >
          <EndpointOption setNeedsRefresh={setNeedsRefresh} />
          <ImpersonateOption setNeedsRefresh={setNeedsRefresh} />
          {Object.values(FLAGS).map((flag) => (
            <FlagOption flag={flag} setNeedsRefresh={setNeedsRefresh} key={`flag-${flag.devName}`} />
          ))}
          {/* Dev links */}
          <WSSeparator />
          <WSText variant="bodySmall" css={{ color: 'white' }}>
            <DevLinksList>
              <li>
                <Link href={RouteUrls.devComponentPlaygroundUrl}>Component Playground</Link>
              </li>
              <li>
                <Link href={RouteUrls.devTokenUrl}>View Token</Link>
              </li>
              <li>
                <Link href={RouteUrls.devSchemaViewerUrl()}>Schema Viewer</Link>
              </li>
              <li>
                <Link href={RouteUrls.v2Syncs}>V2 UI</Link>
              </li>
            </DevLinksList>
          </WSText>
        </WSFlex>
      </StyledCollapsibleContent>
    </StyledCollapsibleRoot>
  );
}

function FlagOption(props: { flag: ClientFlag; setNeedsRefresh: Dispatch<SetStateAction<boolean>> }): JSX.Element {
  const [flagValue, setFlagValue] = useState<boolean>(props.flag.getLocalStorageValue());
  return (
    <WSFlex justify={'between'}>
      <label>{props.flag.friendlyName}</label>
      <input
        type="checkbox"
        checked={flagValue}
        name={props.flag.devName}
        onChange={(e): void => {
          const newValue = e.target.checked;
          props.flag.setLocalStorageValue(newValue);
          setFlagValue(newValue);
          props.setNeedsRefresh(true);
        }}
      />
    </WSFlex>
  );
}

const DevLinksList = styled('ul', {
  paddingLeft: '$cardPadding',
  margin: 0,
  '& li a': {
    color: '$accent1',
  },

  '& li': {
    marginTop: '5px',
  },

  '& li::marker': {
    content: '💥 ',
    fontSize: '$bodyLarge',
  },
});

function EndpointOption(props: { setNeedsRefresh: Dispatch<SetStateAction<boolean>> }): JSX.Element {
  // We rarely use this and there is a risk in accidentally damaging production. Since quite a bit of effort went into
  // building this, starting by disabling for the moment to confirm we don't use it, before eventually deleting.

  // Use chooseEndpoint instead of getEndpoint so we get a sane populated default.
  const [selectedEndpoint, setSelectedEndpoint] = useState(chooseEndpoint());
  console.log('overridde:', selectedEndpoint);
  return (
    <WSFlex key="endpoint-option" direction={'column'} css={{ gap: '6px' }}>
      <label>Endpoint</label>
      <select
        disabled={true}
        value={selectedEndpoint?.toString()}
        name="endpoint"
        onChange={(e): void => {
          const newValue = stringToEnum(e.target.value, Flavor, Flavor.Test);
          setSelectedEndpoint(newValue);
          setStoredEndpointOverride(newValue);
          props.setNeedsRefresh(true);
        }}
      >
        <option value={Flavor.Local}>Local</option>
        <option value={Flavor.Test}>Test</option>
        <option value={Flavor.Staging}>Staging</option>
        <option value={Flavor.Production}>Production</option>
      </select>
    </WSFlex>
  );
}

function ImpersonateOption(props: { setNeedsRefresh: Dispatch<SetStateAction<boolean>> }): JSX.Element {
  function isValidValue(input: string): boolean {
    return (
      input === '' ||
      input.match(/^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/g) !== null
    );
  }
  const [userToImpersonateId, setUserToImpersonateId] = useState<string>(getStoredUserToImpersonateId() ?? '');
  const [isValid, setIsValid] = useState<boolean>(isValidValue(userToImpersonateId));

  return (
    <WSFlex key="impersonate-option" direction={'column'} css={{ gap: '6px' }}>
      <label>User to impersonate</label>
      <WSTextField
        state={isValid ? 'valid' : 'invalid'}
        onChange={(e): void => {
          const trimmed = e.target.value.trim();
          const newIsValid = isValidValue(trimmed);
          setUserToImpersonateId(trimmed);
          if (newIsValid) {
            setStoredUserToImpersonateId(trimmed);
            props.setNeedsRefresh(true);
          }
          setIsValid(newIsValid);
        }}
        value={userToImpersonateId}
        placeholder="User ID"
      />
    </WSFlex>
  );
}

export default DevToolbox;
