import { styled } from 'stitches.config';

const WSCard = styled('div', {
  display: 'block',
  appearance: 'none',
  boxSizing: 'border-box',
  outline: 'none',
  padding: '$cardPadding',
  verticalAlign: 'middle',
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  backgroundColor: 'white',
  textDecoration: 'none',
  color: 'inherit',
  flexShrink: 0,
  position: 'relative',

  borderRadius: '$regular',
  border: '1px solid $gray2',
  transform: 'translateY(0)',
  transition: 'none',
  overflow: 'clip',

  '&::before': {
    boxSizing: 'border-box',
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    opacity: '1',
    borderRadius: '$regular',
    pointerEvents: 'none',
  },

  variants: {
    hoverable: {
      true: {
        cursor: 'pointer',
        transition: '$wsHoverTransition',
        '&:hover': {
          border: '1px solid $gray5',
        },
      },
    },
    // Some designs need you to manage padding manually.
    // Reference $cardPadding manually add correct padding where appropriate.
    manualPadding: {
      true: {
        padding: '0',
      },
    },
  },
});

export default WSCard;
