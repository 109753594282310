import * as SeparatorPrimitive from '@radix-ui/react-separator';
import { styled } from 'stitches.config';

export const WSSeparator = styled(SeparatorPrimitive.Root, {
  border: 'none',
  margin: 0,
  flexShrink: 0,
  backgroundColor: '$gray2',
  cursor: 'default',

  variants: {
    size: {
      '1': {
        '&[data-orientation="horizontal"]': {
          height: '1px',
          width: '100%',
        },

        '&[data-orientation="vertical"]': {
          width: '1px',
        },
      },
      '2': {
        '&[data-orientation="horizontal"]': {
          height: '1px',
        },

        '&[data-orientation="vertical"]': {
          width: '1px',
        },
      },
    },
  },
  defaultVariants: {
    size: '1',
  },
});
