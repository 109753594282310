import { Info } from '@phosphor-icons/react';
import { ChangeEventHandler, FC } from 'react';
import { CSS, styled } from 'stitches.config';
import { WSFlex } from './ws-flex';
import { WSLabel } from './ws-label';

const WSTextField = styled('input', {
  // Reset
  appearance: 'none',
  border: '1px solid $gray2',
  borderRadius: '$regular',
  boxSizing: 'border-box',
  fontFamily: 'inherit',
  margin: '0',
  outline: 'none',
  padding: '0',
  width: '100%',
  WebkitTapHighlightColor: 'rgba(0,0,0,0)',
  '&::before': {
    boxSizing: 'border-box',
  },
  '&::after': {
    boxSizing: 'border-box',
  },

  // Custom
  backgroundColor: 'white',
  color: '$hiContrast',
  fontVariantNumeric: 'tabular-nums',

  '&:-webkit-autofill': {
    boxShadow: 'inset 0 0 0 1px $primaryWithAlpha, inset 0 0 0 100px $primaryWithAlpha',
  },

  '&:-webkit-autofill::first-line': {
    fontFamily: '$untitled',
    color: '$hiContrast',
  },

  '&::placeholder': {
    color: '$gray5',
  },
  '&:disabled': {
    pointerEvents: 'none',
    backgroundColor: '$slate2',
    color: '$slate8',
    cursor: 'not-allowed',
    '&::placeholder': {
      color: '$slate7',
    },
  },
  '&:read-only': {
    backgroundColor: '$slate2',
    '&:focus': {
      boxShadow: 'inset 0px 0px 0px 1px $colors$slate7',
    },
  },

  variants: {
    size: {
      '1': {
        height: '$5',
        fontSize: '$bodySmall',
        px: '$1',
        lineHeight: '$sizes$5',
        '&:-webkit-autofill::first-line': {
          fontSize: '$bodySmall',
        },
      },
      '2': {
        height: '50px',
        fontSize: '$bodyLarge',
        padding: '16px',
        '&:-webkit-autofill::first-line': {
          fontSize: '$bodyLarge',
        },
      },
      inherit: {
        height: '100%',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        borderRadius: '4px',
      },
    },
    variant: {
      default: {
        padding: '10px 12px',
        fontSize: '$bodyMedium',
        height: '40px',
        color: '$gray9',
        '&:active': {
          border: '1px solid $accent6',
        },
        '&:focus': { border: '1px solid $accent6' },
        '&::placeholder': {
          color: '$gray5',
        },
      },
      ghost: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        '@hover': {
          '&:hover': {
            // boxShadow: 'inset 0 0 0 1px $colors$slateA7',
          },
        },
        '&:focus': {
          // backgroundColor: '$loContrast',
          // boxShadow: 'inset 0px 0px 0px 1px $primaryWithAlpha, 0px 0px 0px 1px $primaryWithAlpha',
        },
        '&:disabled': {
          backgroundColor: 'transparent',
        },
        '&:read-only': {
          backgroundColor: 'transparent',
        },
      },
    },
    state: {
      invalid: {
        boxShadow: 'inset 0 0 0 1px $colors$red7',
        '&:focus': {
          boxShadow: 'inset 0px 0px 0px 1px $colors$wsRedLight, 0px 0px 0px 1px $colors$wsRedLight',
        },
        backgroundColor: '$wsRedLight',
      },
      valid: {
        boxShadow: 'inset 0 0 0 1px $colors$green7',
        '&:focus': {
          boxShadow: 'inset 0px 0px 0px 1px $colors$wsGreenLight, 0px 0px 0px 1px $colors$wsGreenLight',
        },
      },
    },
    cursor: {
      default: {
        cursor: 'default',
        '&:focus': {
          cursor: 'text',
        },
      },
      text: {
        cursor: 'text',
      },
    },
  },
  defaultVariants: {
    size: '2',
    variant: 'default',
  },
});

export const WSTextFieldWithLabel: FC<{
  id: string;
  label?: string;
  learnMoreLink?: string;
  css?: CSS;
  type?: string;
  /** Disables autofill UI elements in password managers like LastPass (only one so far). */
  disableAutofill?: boolean;
  placeholder?: string;
  value?: string | number;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  state?: 'invalid' | 'valid';
}> = ({ id, label, learnMoreLink, css, disableAutofill, onChange, state, ...rest }) => {
  return (
    <WSFlex direction="column" css={{ gap: '8px' }} fullWidth>
      {label && (
        <WSFlex direction="row" css={{ gap: '4px', alignItems: 'center' }}>
          <WSLabel htmlFor={id}>{label}</WSLabel>
          {learnMoreLink && (
            <a href={learnMoreLink} target="_blank" rel="noreferrer" style={{ display: 'flex' }}>
              <Info size={16} />
            </a>
          )}
        </WSFlex>
      )}
      <WSTextField
        onChange={onChange}
        data-lpignore={disableAutofill ? 'true' : ''}
        id={id}
        variant="default"
        css={css}
        state={state}
        {...rest}
      />
    </WSFlex>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const WSSearchTextField: FC<{ placeholder?: string; value?: string; onChange?: (e: any) => void }> = ({
  ...props
}) => {
  return (
    <WSTextField
      {...props}
      css={{
        paddingLeft: '36px',
        backgroundImage: `url(/assets/search.svg)`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionY: 'center',
        backgroundPositionX: '13px',
      }}
    />
  );
};
export default WSTextField;
